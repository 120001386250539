import React, { useState, useEffect } from 'react';
import './styles.css'; // Supondo que os estilos estejam em um arquivo separado

const AnimatedInputs = () => {
  const [input1Visible, setInput1Visible] = useState(false);
  const [input2Visible, setInput2Visible] = useState(false);
  const [input1Text, setInput1Text] = useState('');
  const [input2Text, setInput2Text] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [hideInputs, setHideInputs] = useState(false);
  const [showPlaceholder2, setShowPlaceholder2] = useState(false); // Estado para o placeholder do segundo input

  useEffect(() => {
    startFirstAnimation();
  },[]);

  const startFirstAnimation = () => {
    setInput1Visible(true);

    // Simula a digitação no primeiro input
    setTimeout(() => {
      simulateTyping('Paciente João, Obstrução Nasal Crônica...', setInput1Text, () => {
        // Após a digitação do primeiro input, mostra o segundo input
        setTimeout(() => {
          setInput2Visible(true); // Agora só muda para visível após a digitação
          setShowPlaceholder2(true); // Mostra o placeholder do segundo input
          
          // Simula a digitação no segundo input após um breve atraso
          setTimeout(() => {
            simulateTyping('Desvio septal para esquerda', setInput2Text, () => {
              // Após a digitação, esconde os inputs de forma suave
              setTimeout(() => {
                setHideInputs(true);
              }, 1000); // Aguarda um segundo após a digitação do segundo input
            });
          }, 1500); // Tempo de espera antes de iniciar a digitação do segundo input
        }, 500); // Tempo de espera antes de mostrar o segundo input
      });
    }, 2000);
  };

  const simulateTyping = (text, setText, callback) => {
    let index = 0;
    setIsTyping(true);
    const interval = setInterval(() => {
      setText(text.slice(0, index + 1)); // Atualiza o texto sem embaralhamento
      index++;
      if (index === text.length) {
        clearInterval(interval);
        setIsTyping(false);
        if (callback) callback();
      }
    }, 100); // Velocidade de digitação
  };

  return (
    <div className="animation1" id="animation1">
      {/* Primeiro input */}
      <div className={`input-container ${input1Visible ? 'input-visible' : 'input-hidden'}`}>
        <input
          type="text"
          value={input1Text}
          className={`input-hidden ${isTyping ? 'typing' : ''} ${hideInputs ? 'slide-out' : ''}`}
          readOnly
          placeholder="Digite um breve histórico do paciente"
        />
      </div>

      {/* Segundo input */}
      {input2Visible && ( // Condicional para mostrar o segundo input
        <div className={`input-container ${input2Visible ? 'input-visible' : ''}`}>
          <input
            type="text"
            value={input2Text}
            className={`input-hidden ${isTyping ? 'typing' : ''} ${hideInputs ? 'slide-out' : ''}`}
            readOnly
            placeholder={showPlaceholder2 ? "Insira o laudo" : ""} // Mostra o placeholder primeiro
          />
        </div>
      )}
    </div>
  );
};

export default AnimatedInputs;

import { useEffect, useState } from 'react';
import './App.css';
import Animation1 from './components/Animation1/App';
import Animation2 from './components/Animation2/App';
import Animation3 from './components/Animation3/App';
import Animation4 from './components/Animation4/App';

function App() {
  const [moveComplete, setMoveComplete] = useState(false);
  const [currentAnimation, setCurrentAnimation] = useState(0);
  const [restartVisible, setRestartVisible] = useState(false);

  useEffect(() => {
    // Iniciar a animação inicial (movimento do parágrafo)
    setTimeout(() => {
      setMoveComplete(true);
    }, 1000); // Delay para a animação do parágrafo se mover

    // Executar animações sequenciais
    setTimeout(() => {
      setCurrentAnimation(1); // Começa a Animation1
    }, 2000); // Começa Animation1 após o parágrafo subir

    setTimeout(() => {
      setCurrentAnimation(2); // Começa a Animation2
    }, 15000); // Começa Animation2 3 segundos depois da Animation1 começar

    setTimeout(() => {
      setCurrentAnimation(3); // Começa a Animation3
    }, 22000); // Começa Animation3 3 segundos depois da Animation2

    setTimeout(() => {
      setCurrentAnimation(4); // Começa a Animation4
    }, 31000); // Começa Animation4 3 segundos depois da Animation3

    setTimeout(() => {
      setRestartVisible(true);
    }, 33500);

  }, []);

  const handleRestart = () => {
    setMoveComplete(false);
    setCurrentAnimation(0);
    setRestartVisible(false);

    // Reinicia o ciclo de animações
    setTimeout(() => {
      setMoveComplete(true);
    }, 1000);

    setTimeout(() => {
      setCurrentAnimation(1);
    }, 2000);

    setTimeout(() => {
      setCurrentAnimation(2);
    }, 15000);

    setTimeout(() => {
      setCurrentAnimation(3);
    }, 22000);

    setTimeout(() => {
      setCurrentAnimation(4);
    }, 31000);

    // Exibir o botão de "Recomeçar" novamente após o ciclo
    setTimeout(() => {
      setRestartVisible(true);
    }, 33500);
  };


  return (
    <div className="App">
      <div class="navbar">
      <h1>
        <img src={`${process.env.PUBLIC_URL}/images/menu.svg`} alt="menu" />
        LetsDoc.
      </h1>
    </div>

    <div class="container">
      <p className={`message ${moveComplete ? 'move-up' : ''}`}>
        Imagine o pedido médico dos seus pacientes<br />em segundos
      </p>
      {currentAnimation === 1 && <Animation1 />}
      {currentAnimation === 2 && <Animation2 />}
      {currentAnimation === 3 && <Animation3 />}
      {currentAnimation === 4 && <Animation4 />}
      <button className={`btn ${moveComplete ? 'move-down' : ''}`}>Contrate</button>

      <button className={`restart-btn ${restartVisible ? 'visible' : ''}`} onClick={handleRestart}>
        <span class="material-symbols-outlined">restart_alt</span>Rever explicação
      </button>
    </div>

    {/* Seção Quem Já Utilizou */}
    {/* <section class="testimonials">
      <div class="section-container">
        <h2>QUEM JÁ UTILIZOU</h2>
        <div class="testimonials-container">
        
          <div class="testimonial-card">
            <div class="testimonial-header">
              <img
                src={`${process.env.PUBLIC_URL}/images/cirurgiao.png`}
                alt="Dr Marcelo"
                class="testimonial-image"
              />
              <h3>Dr Marcelo</h3>
              <p><em>Cirurgião</em></p>
            </div>
            <div class="testimonial-content">
              <p>"Adorei o software, pois ele me economizou horas."</p>
            </div>
          </div>

         
          <div class="testimonial-card">
            <div class="testimonial-header">
              <img
                src={`${process.env.PUBLIC_URL}/images/pediatra.png`}
                alt="Drª. Fernanda"
                class="testimonial-image"
              />
              <h3>Drª. Fernanda</h3>
              <p><em>Pediatra</em></p>
            </div>
            <div class="testimonial-content">
              <p>"Uma ferramenta indispensável no meu dia a dia."</p>
            </div>
          </div>

          
          <div class="testimonial-card">
            <div class="testimonial-header">
              <img
                src={`${process.env.PUBLIC_URL}/images/cardiologista.png`}
                alt="Dr João"
                class="testimonial-image"
              />
              <h3>Dr. João</h3>
              <p><em>Cardiologista</em></p>
            </div>
            <div class="testimonial-content">
              <p>"Simplificou muito o processo de atendimento."</p>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    {/* Seção Planos */}
    <section class="plans">
      <div class="section-container">
        <h2>PLANOS</h2>
        <div class="plans-container">
          <div class="plan-card">
            <h3>Plano Essencial</h3>
            <ul>
              <li>10 pedidos por mês</li>
              <li>Até 50 pacientes</li>
              <li>Suporte básico</li>
            </ul>
            <button>Escolher</button>
          </div>
          {/* <div class="plan-card">
            <h3>Plano Profissional</h3>
            <ul>
              <li>50 pedidos por mês</li>
              <li>Até 200 pacientes</li>
              <li>Suporte prioritário</li>
            </ul>
            <button>Escolher</button>
          </div>
          <div class="plan-card">
            <h3>Plano Premium</h3>
            <ul>
              <li>Pedidos ilimitados</li>
              <li>Pacientes ilimitados</li>
              <li>Suporte dedicado</li>
            </ul>
            <button>Escolher</button>
          </div> */}
        </div>
      </div>
    </section>

    {/* Seção de Contato */}
    <section class="contact-section">
      <div class="section-container">
        <h2>CONTATO</h2>
        <p>
          <a href="mailto:contato@letsdoc.com.br">contato@letsdoc.com.br</a>
        </p>
      </div>
    </section>

    {/* Rodapé */}
    <footer class="footer">
      <a href="#">Termos de uso</a>
      <span>Direitos reservados LetsDoc. - 2024</span>
    </footer>

    {/* Botão WhatsApp */}
    <a
      href="https://wa.me/+55 21 98833-3106"
      class="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={`${process.env.PUBLIC_URL}/images/whatsApp@2x.png`} alt="WhatsApp" />
    </a>
    </div>
  );
}

export default App;

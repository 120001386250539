import React, { useEffect, useState } from 'react';
import './styles.css'; // Inclua seu CSS

const Animation3 = () => {
    const [titleVisible, setTitleVisible] = useState(false); // Novo estado para o título
    const [itemsVisible, setItemsVisible] = useState([false, false, false]);
    const [checkVisible, setCheckVisible] = useState(false); // Novo estado para o check
    const [fadeOut, setFadeOut] = useState(false); // Estado para o desaparecimento
    const texts = [
        "30501369 - Septoplasatia",
        "30501350 - Rinosseptoplastia",
        "30501458 - Turbinectomia"
    ];

    const startThirdAnimation = () => {
        // Mostra o título
        setTimeout(() => {
            setTitleVisible(true);
        }, 1000); // Mostra o título imediatamente

        // Faz os itens aparecerem um por um
        itemsVisible.forEach((_, index) => {
            setTimeout(() => {
                setItemsVisible(prev => {
                    const newVisible = [...prev];
                    newVisible[index] = true; // Torna o item visível
                    return newVisible;
                });

                // Se for o último item, mostra o check
                if (index === itemsVisible.length - 1) {
                    setTimeout(() => {
                        setCheckVisible(true); // Torna o check visível após o último item
                    }, 1000); // Delay para mostrar o check
                }
            }, (index + 2) * 1000); // Aparecer um item por vez a cada segundo
        });

        // Iniciar o desaparecimento após o fim da animação
        setTimeout(() => {
            setFadeOut(true); // Ativa o fade-out após o checkmark aparecer
        }, 7000); // Define o tempo para começar o fade-out
    };

    useEffect(() => {
        startThirdAnimation(); // Inicia a animação quando o componente é montado
    }, []);

    return (
        <div id="animation3" className={`${fadeOut ? 'fade-out' : ''}`}>
            <h2 className={`title ${titleVisible ? 'visible' : 'title-hidden'}`}>Selecione o código</h2> {/* Renderiza o título */}
            <ul className="check-list">
                {itemsVisible.map((visible, index) => (
                    <li key={index} className={visible ? 'check-visible' : 'hidden'}>
                        <div className="checkbox">
                            {index === 0 && checkVisible && <div className="checkmark">✔</div>} {/* Mostra o checkmark */}
                        </div>
                        {texts[index]}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Animation3;
